@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 142.1 76.2% 36.3%;
    --graph: 142 76% 36%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142.1 76.2% 36.3%;
    --radius: 0.5rem;
    --foreground-opposite: 0 0% 95%;
    --muted-foreground-opposite: 240 5% 64.9%;
  }
  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 0 0% 9%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 142.1 66.6% 53.3%;
    --graph: 142 76% 36%;
    --primary-foreground: 144.9 80.4% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 74% 39%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142.4 71.8% 29.2%;
    --foreground-opposite: 240 10% 3.9%;
    --muted-foreground-opposite: 240 3.8% 46.1%;
  }
}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
:root {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-left {
  from {
    transform: translateX(3px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
.fade-out {
  animation-name: fade-out;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 0.2s;
}

.fade-in-semi {
  animation-timing-function: ease-in;
  animation-name: fade-in;
  animation-duration: 0.35s;
}

.fade-in-gentle {
  animation-timing-function: ease-in;
  animation-name: fade-in;
  animation-duration: 0.5s;
}

.fade-in-left {
  animation-name: fade-in-left;
  animation-duration: 0.8s;
}

@keyframes scale-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.scale-pulse {
  animation: scale-pulse 1s ease-in-out infinite;
}

@keyframes tilter {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
.small-tilter, .animation-Paul {
  animation: small-tilter 1s ease-in-out infinite;
}

@keyframes small-tilter {
  0% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}
@keyframes bounceAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
.bounce, .animation-Tomasz {
  animation: bounceAnimation 0.8s cubic-bezier(0.5, 1.7, 0.7, 1) infinite;
}

@keyframes small-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
.small-pulse, .animation-Robbie {
  animation: small-pulse 1.1s ease-in-out infinite;
}

@keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}
.rotating-0 {
  animation-name: rotating;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.rotating {
  animation: rotating 2s linear infinite;
}

.rotating-md {
  animation: rotating 6s linear infinite;
}

.rotating-slow {
  animation: rotating 15s linear infinite;
}

.overflow-safari-fix {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.live-icon {
  display: inline-block;
  position: relative;
  background-color: hsl(var(--destructive));
  width: 12px;
  height: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
}
.live-icon:before {
  content: "";
  display: block;
  position: absolute;
  background-color: hsl(var(--destructive));
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: live 2s ease-in-out infinite;
  z-index: -1;
}

@keyframes live {
  0% {
    transform: scale(1, 1);
    opacity: 0.6;
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: hsl(var(--destructive));
    opacity: 0;
  }
}
.one-line-text {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
             supported by Chrome, Edge, Opera and Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsl(var(--background));
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(var(--muted-foreground));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: hsl(var(--card));
}

.blur-12 {
  -webkit-backdrop-filter: blur(12px); /* Add this line first, it fixes blur for Safari*/
  backdrop-filter: blur(12px); /* This line activates blur*/
}/*# sourceMappingURL=index.css.map */